<template>
	<div style="position: relative;" id="editoerbox">
		<div id="zxedit" style="padding: 10px; line-height: 20px; background-color: #FFFFFF; min-height: 100px; font-size: 16px; position: relative; z-index: 2; "
			contenteditable="true"  @input="resetClick">
			

		</div>
		
		<div v-if="tcinfo&&tcinfo.ctype&&(tcinfo.ctype.news||tcinfo.ctype.voice||tcinfo.ctype.video)"
			style="padding: 4px 10px; border: 1px solid #ccc; margin-top: 10px;">
			<div v-if="tcinfo&&tcinfo.ctype&&tcinfo.ctype.news" class="filebtn"
				:style="{backgroundImage:'url(./static/images/img.png)'}" @click="clickImage">

			</div>
			<div v-if="tcinfo&&tcinfo.ctype&&tcinfo.ctype.voice" class="filebtn sml"
				:style="{backgroundImage:'url(./static/images/voice.png)'}" @click="clickVoice"></div>
			<div v-if="tcinfo&&tcinfo.ctype&&tcinfo.ctype.video" class="filebtn sml"
				:style="{backgroundImage:'url(./static/images/video.png)'}" @click="clickVideo"></div>
		</div>

		<div style="height: 50px;">
			<div v-if="process"
				style=" border: 1px solid #ccc; height: 14px; border-radius: 8px; position: relative; margin-top: 10px;">
				<div class="inprocess" style="width: 50%;"></div>
				<div
					style="width: 100%; height: 100%; text-align: center; font-size: 12px; line-height: 14px; position: absolute; top: 0;">
					{{process}}%</div>
			</div>
		</div>

		<div class="btnStarzx" @click="submiteWrite">{{opt.btntxt}}</div>
		<div style="width: 0; height: 0; overflow: hidden;">
			<input type="file" accept="image/jpeg,image/jpg,image/png" name="fileimage" id="fileimage"
				@change="uploadImage" />

			<input type="file" name="filevideo" id="filevideo" accept="video/mp4" @change="uploadVideo" />
		</div>

		<div v-show="showVoice"
			style="width: 100%; height: 100%;position: fixed; left: 0; top: 0; z-index: 9; background-color: rgba(0,0,0,0.5);">
			<div style="position: absolute; bottom:120px; width: 100%; ">
				<div style="width: 200px; margin: 0 auto; background: #FFFFFF; padding: 20px;border-radius: 4px;">
					<div style="width: 50%; margin: 0 auto;">
						<img src="https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578046145691893.gif"
							style="width: 100%;" />
					</div>
					<div style="text-align: center;">
						{{second/10}}秒
					</div>

					<div class="voicebtn" @click="closeVoice(1)">我说完了</div>
					<div class="voicebtnclose" @click="closeVoice(0)">取消</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['tc', 'opt'],
		data() {
			return {
				ImageList: [],
				msgcontent: "",
				taped: false,
				zjid: 0,
				tcid: 0,
				orderid: 0,
				tcinfo: null,
				zjinfo: null,
				lastDom: null,
				process: 0,
				showVoice: false,
				second: 0,
				placeholer:true
			}
		},
		mounted() {
			this.tcinfo = this.tc

			this.$store.dispatch("SHARE", {
				query: {
					shareurl: window.location.href.split('#')[0]
				},
				opt: {
					title: "过敏宝宝-专家咨询",
					desc: "欢迎咨询过敏宝宝，权威专家为您提供专业解答！",
					link: window.location.href.split("#")[0] + "#/zjlist",
					imgUrl: "https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1579008835861760.jpg",
					success: _ => {

					}
				}
			}).then(result => {
				let t = setInterval(() => {
					if (window.wx) {
						clearInterval(t);
						wx.onVoiceRecordEnd({
							// 录音时间超过一分钟没有停止的时候会执行 complete 回调
							complete: (res) => {
								
								var localId = res.localId;
								this.uploadVoice(localId, voice => {
									if (voice.data.url) {
										let img = "<audio src='" + voice.data.url +
											"' webkit-playsinline playsinline  style='width:100%;vertical-align: middle;' controls ></audio>"
										img += "<div><br /></div>"
									}
									if (this.lastDom) {
										$(this.lastDom).append(img)
									} else {
										$("#zxedit").append(img)
									}

								})
							}
						})
					}
				}, 500)
			})






		},
		methods: {
			clickImage() {
				document.getElementById('fileimage').click();
			},
			uploadImage(e) {

				let files = document.getElementById("fileimage").files;



				let file = {
					file: files[0],
					onProgress: e => {

						this.process = parseInt(e.loaded / e.total * 10000) / 100
					},
					onSuccess: e => {
						this.process = 0;
						let img = "<img src='" + e.src.replace("https", "http") +
							"' style='width:100%;vertical-align: middle;' />"
						img += "<div><br /></div>"
						if (this.lastDom) {
							$(this.lastDom).append(img)
						} else {
							$("#zxedit").append(img)
						}
					}
				}
				this.uploadOss(file)



			},
			clickVideo() {
				document.getElementById('filevideo').click();
			},
			uploadVideo(e) {

				let files = document.getElementById("filevideo").files;
				let file = {
					file: files[0],
					onProgress: e => {

						this.process = parseInt(e.loaded / e.total * 10000) / 100
					},
					onSuccess: e => {
						this.process = 0;
						let img = "<video src='" + e.src +
							"' webkit-playsinline playsinline  style='width:100%;vertical-align: middle;' controls ></video>"
						img += "<div><br /></div>"
						if (this.lastDom) {
							$(this.lastDom).append(img)
						} else {
							$("#zxedit").append(img)
						}
					}
				}
				this.uploadOss(file)





			},
			clickVoice(e) {
				this.startVoice()
				
			},
			resetClick() {
				this.placeholer = false


			},
			submiteWrite() {
				this.$parent.submiteWrite($("#zxedit").html())
			},
			closeVoice(s) {
				if (s == 0) {
					this.showVoice = false
					wx.stopRecord()
				} else {
					this.endVoice()
				}
			},
			startVoice() {
				
				wx.startRecord({
					success:e=>{
						this.showVoice = true
						this.timer = setInterval(() => {
							this.second += 1;
						}, 100)
					}
				});
				
			},
			endVoice() {
				this.showVoice = false;
			
				clearInterval(this.timer);
				if (this.second > 10) {
					let lasttime = this.second;
					wx.stopRecord({
						success: (res) => {
							this.log = res.localId
							var localId = res.localId;
							this.uploadVoice(localId, voice => {
								
								let img = "<audio src='" + voice.data.url +
									"' webkit-playsinline playsinline  style='width:100%;vertical-align: middle;' controls ></audio>"
								img += "<div><br /></div>"
								if (this.lastDom) {
									$(this.lastDom).append(img)
								} else {
									$("#zxedit").append(img)
								}
							})
						}
					});
			
			
			
				} else {
					$Toast("时间太短，请重试");
					wx.stopRecord({
						success: (res) => {
			
						}
					});
					//this.second = 0;
				}
				
				this.second = 0;
				
			},
			uploadVoice(localId, success) {
				$("#loading").show()
				wx.uploadVoice({
					localId: localId, // 需要上传的音频的本地ID，由stopRecord接口获得
					isShowProgressTips: 0, // 默认为1，显示进度提示
					success: (res2) => {
						var serverId = res2.serverId; // 返回音频的服务器端ID
						this.$store.dispatch("POST", {
							url: "/api/zixun/?action=uploadvoice",
							query: {
								serverid: serverId
							}
						}).then(result => {
							if (success) {
								success(result)
							}
							$("#loading").hide()
						})
					}
				});
			
			
			
			},


		}
	}
</script>

<style scoped="scoped">
	.btnStarzx {


		width: 90%;
		height: 40px;
		line-height: 40px;
		background: #0C85E1;
		color: #FFFFFF;
		text-align: center;
		border-radius: 4px;
		margin: 0 auto;
		margin-bottom: 100px;
		margin-top: 20px;
	}

	.btnStarzx:active {
		background: #26A1FF;
	}

	.filebtn {
		width: 30px;
		height: 30px;
		background-size: cover;

		display: inline-block;
		vertical-align: top;
		margin-right: 20px;
	}

	.sml {
		transform: scale(0.9, 0.9);
	}

	.inprocess {
		width: 0%;
		height: 100%;
		background-color: orange;
		border-radius: 7px;
	}

	.voicebtn {
		width: 70%;
		margin: 0 auto;
		background-color: #007AFF;
		color: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 4px;
		margin-top: 20px;
	}

	.voicebtn:active {
		background-color: #43AEFF;
	}

	.voicebtnclose {
		color: #ccc;
		text-align: center;
		margin-top: 30px;
	}
</style>

<style type="text/css">
	#zxedit{
	    position:relative;
		-webkit-user-select:text;
	}
	
	
</style>
