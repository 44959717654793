<template>
	<div id="innerpage" style="overflow: auto;">
		<div v-for="zx in DataList" >
			<div style="padding: 5%;font-size: 12px;border-bottom: 1px solid #f6f6f6; ">
				<div style="line-height: 30px;" v-if="myinfo">
					<img :src="zx.headimgurl" style="width: 30px; height: 30px; vertical-align: middle;" alt="">
					<span style="margin-left: 10px;">{{zx.nickname}} 发起咨询</span>
					<div style="float: right;">
						{{zx.cdate}}
					</div>
				</div>
				<div style="padding-top: 10px; color: #000000;font-size: 14px; word-break: break-all;" v-html="zx.msgcontent">

				</div>


			</div>


			<div style="padding:2% 5%;font-size: 12px;" v-for="(zx,idx) in ReplyList" :key="idx">
				

				<div v-if="zjinfo&&zx.reply_uid==zxuid" style=" border-bottom: 1px solid #f6f6f6; padding-bottom: 10px;">
					<div style="line-height: 30px;">
						<img :src="zx.headimgurl" style="width: 30px; height: 30px; vertical-align: middle; border-radius: 4px;" alt="">
						<span style="margin-left: 10px;">{{zx.nickname}} 追加咨询</span>
						<div style="float: right;">
							{{zx.zj_confirmdate}}
						</div>
					</div>
					<div style="padding-top: 10px; color: #000000;font-size: 14px;word-break: break-all;" v-html="zx.replycontent">
					</div>
					
				</div>
				<div v-else style=" border-bottom: 1px solid #f6f6f6; padding-bottom: 10px;">
					<div style="line-height: 30px; text-align: right;">
						<span style="margin-right: 10px;">{{(zjinfo.zj_uid == zx.reply_uid||zx.reply_uid==-1)?"【"+zjinfo.title_name+"】"+zjinfo.zj_name:"【营养师】"+zx.nickname}} {{zx.zj_confirmdate}}</span>
						<img :src="(zjinfo.zj_uid == zx.reply_uid||zx.reply_uid==-1)?zjinfo.zj_headimg:zx.headimgurl" style="border-radius: 4px;width: 30px; height: 30px; vertical-align: middle;" alt="">
					</div>
					<div style="padding-top: 10px; color: #000000;font-size: 14px;word-break: break-all; text-align: right;" v-html="zx.replycontent">
					
					</div>
				</div>


			</div>


			<div style="width: 90%; margin: 0 auto; position: relative;" v-if="can_zhuijia">
				<div style="padding-bottom: 10px;">回复：</div>
				<zxeditor :tc="tcinfo" v-if="tcinfo" :opt="{btntxt:'提交回复'}"></zxeditor>
			</div>

		</div>
		<div style="height: 60px;"></div>



	</div>
</template>

<script>
	import zxeditor from "./com/zxeditor.vue"
	export default {
		components: {
			zxeditor
		},
		data() {
			return {
				ImageList: [],
				
				msgcontent: "",
				taped: false,
				DataList: [],
				ReplyList: [],
				inputtype: "keybord",
				showVoice: false,
				ContentList: [],
				timer: 0,
				second: 0,
				txt: "",
				log: "",
				zjheader: "",
				replied: false,
				myinfo: null,
				tcinfo: null,
				zjinfo: null,
				can_zhuijia:true,
				isreplyagin:false,
				zxuid:0//发起人的uid

			}
		},
		mounted() {
			this.getList();
			this.getMyinfo()
			$("#innerpage").height($(window).height())

		},
		methods: {

			getZJINfo(zjid) {
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjone&id=" + zjid
				}).then(res => {
					this.zjinfo = res.data.rows[0]
					for(let r of this.ReplyList){
						if(r.reply_uid==this.zjinfo.id){
							this.isreplyagin = true
							console.log(this.isreplyagin)
						}
					}
				})
			},
			getMyinfo() {
				this.$store.dispatch("POST", {
					url: "/api/wx/?action=me"
				}).then(result => {
					this.myinfo = result.data.data.rows[0]
				})

			},
			getTCInfo(tcid) {
				this.$store.dispatch("POST", {
					url: "/api/zixun/?action=taocan_one",
					query: {
						tcid: tcid
					}
				}).then(res => {
					if (res.data) {
						if (res.data.ctype) {
							res.data.ctype = JSON.parse(res.data.ctype)
						}
						this.tcinfo = res.data
						
					}
				})
			},

			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zxinfo&id=" + this.$route.params.id
				}).then(result => {
					for (let item of result.data.zx) {
						this.zxuid = item.uid
						item.msgcontent = ( item.msgcontent);
						// item.files = JSON.parse(item.files.replace(/\\"/g, "\""));
						item.cdate = this.getCdate(item.cdate)
						this.zjheader = item.zj_headimg
						this.replied = item.replied == 1 ? true : false
						this.getTCInfo(item.tc_id)
						this.getZJINfo(item.zj_id)
					}
					if (result.data.reply) {
						for (let item of result.data.reply) {
							item.replycontent =( item.replycontent);

							item.zj_confirmdate = this.getCdate(item.zj_confirmdate)
							
						}
					}
					this.DataList = result.data.zx;
					this.ReplyList = result.data.reply;
					$("#loading").hide()
					setTimeout(() => {
						this.$previewRefresh()
					}, 1000)
				})
			},


			getCdate(e) {
				let d = new Date(e);
				let y = d.getFullYear();
				let m = d.getMonth() + 1;
				let day = d.getDate();
				let hour = d.getHours()
				let min = d.getMinutes()
				if (m < 10) m = "0" + m
				if (day < 10) day = "0" + day
				if (hour < 10) hour = "0" + hour
				if (min < 10) min = "0" + min

				return y + "-" + m + "-" + day + " " + hour + ":" + min
			},

			submiteWrite(e) {
				let content = e;
				if (!content) {
					return;
				}
				if (this.taped) {
					return
				} else {
					setTimeout(() => {
						this.taped = false
					}, 2000)
				}
				
				
				$confirm({
					msg: "确认提交回复?",
					okcb: _ => {
						this.taped = true;
						$("#loading").show();
						this.$store.dispatch("POSTJSON", {
							url: "/api/zixun/?action="+(this.isreplyagin?'zx_replaymore':"zxreply"),
							para: {
								zx_id: this.$route.params.id,
								zjid:this.myinfo.id,
								msgcontent: (content)
							}
						}).then(result => {
							let data = result.data


							if (data.code == 200 && data.msg == "ok") {
								setTimeout(() => {
									$alert({
										msg: "提交成功",
										okcb: () => {
											window.history.back()
										}
									})
								}, 300)

							} else {
								setTimeout(() => {
									$alert({
										msg: data.msg
									})
								}, 300)

							}

							$("#loading").hide()
						})
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.imagebox {
		width: 29vw;
		height: 29vw;
		margin-right: 1.5vw;
		display: inline-block;
		vertical-align: top;
		margin-bottom: 1.5vw;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border: 1px solid #f6f6f6;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
	}

	.imagebox:nth-child(3n) {
		margin-right: 0;
	}

	.imagebox img {
		opacity: 0;
	}

	.imagebox2 {
		width: 100%;
		height: 23vw;

	}

	.voice {
		width: 40px;
		height: 40px;
		float: left;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578034311383266.png);
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.keybord {
		width: 40px;
		height: 40px;
		float: left;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578034311267128.png);
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.addicon {
		width: 40px;
		height: 40px;
		float: right;
		background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1578034311350187.png);
		background-size: 60%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.inputarea {
		margin-left: 40px;
		margin-right: 40px;
		height: 100%;
		-webkit-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		touch-callout: none;


	}

	.inputarea div {
		height: 30px;
		text-align: center;
		line-height: 30px;
		border: 1px solid #f6f6f6;
		transform: translateY(4px);
		border-radius: 4px;
	}

	.inputarea div:active {
		background-color: #f6f6f6;
	}

	.btnreply {
		position: fixed;
		width: 100%;
		height: 40px;
		bottom: 0;
		left: 0;
		background: #007AFF;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		border-top: 1px solid #f6f6f6;
	}

	.replyitem {
		padding-bottom: 20px;
		position: relative;
		word-break: break-all;
		color: #000000;
	}

	.remove {
		position: absolute;
		width: 20px;
		height: 20px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/8586239857155945387.png);
		background-size: cover;
		right: -22px;
		top: 0px;
	}

	.btnreplyconfirm {
		width: 80%;
		margin: 0 auto;
		height: 40px;
		border-radius: 4px;
		line-height: 40px;
		background: #007AFF;
		color: #FFFFFF;
		text-align: center;
	}

	.zjheader {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		float: left;
	}

	.zjheader img {
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}

	.zjword {
		margin-left: 50px;
		min-height: 45px;
		padding-top: 2px;
		color: #000000;
	}

	.repimage {
		width: 30%;
		display: inline-block;
		margin-right: 3%;
		margin-bottom: 6px;
	}

	.voicebox {
		width: 50%;
		height: 20px;
		padding: 4px;
		background-color: #0000FF;
		border-radius: 14px;
		background-image: url(//gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/157975006840994.png);
		background-size: 20px 20px;
		background-position: 4px 4px;
		background-repeat: no-repeat;
		min-width: 50px;
	}

	.voicebox div {
		margin-left: 25px;
		line-height: 20px;
		color: #FFFFFF;
	}

	.voicebox:active {
		background-color: #007AFF;
	}
</style>
